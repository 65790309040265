import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "curriculum-detail-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "curriculum-box" }
const _hoisted_4 = {
  key: 0,
  class: "curriculum-top-box"
}
const _hoisted_5 = { class: "curriculum-into-box" }
const _hoisted_6 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import ModuleBoard from "@/components/ModuleBoard/index.vue";
import { getMyBuyCertListInfoInterface } from "@/types/index"
import { reactive } from 'vue'
import { getMyBuyCertList } from "@/api/curriculum-list"
import { selectCertificateList } from "@/api/home-list"
import { useRoute } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute()
let getMyBuyCertListInfo: getMyBuyCertListInfoInterface = reactive({
    list: [],
    isRequireHeader: false,
})
if (route.params.type == "1") {
    getMyBuyCertList().then(res => {
        const data = res.data;
        getMyBuyCertListInfo.list = data
    })
} else {
    selectCertificateList().then(res => {
        const data = res.data;
        getMyBuyCertListInfo.list = data
    })
}





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_unref(route).params.type == '1')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
            _createElementVNode("span", null, "我的课程", -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(ModuleBoard, { certificateInfo: _unref(getMyBuyCertListInfo) }, null, 8, ["certificateInfo"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(Footer)
    ])
  ]))
}
}

})