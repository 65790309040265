import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "adapt-box swiper-box" }
const _hoisted_2 = { class: "swiper-into-box" }
const _hoisted_3 = { class: "left-swiper" }
const _hoisted_4 = { class: "right-swiper" }
const _hoisted_5 = ["src"]

import 'swiper/swiper.min.css';
import { SwiperInfoInterface } from "@/types/index"
import { selectBeanBySpaceId } from "@/api/home-list"
import { ref } from "vue"
// import { Lazy } from 'swiper';

// let modules = [Lazy]


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    swiperInfo: {}
  },
  setup(__props: any) {

const props = __props

// 右侧背景图
const homeBackground: any = ref({
    right: "",
})

selectBeanBySpaceId({ adSpaceId: "555343142908329984" }).then(res => {
    const { data } = res;
    homeBackground.value.right = data.img
})


// 555343142908329984


return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_swiper, { "slides-per-view": 1 }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.swiperInfo.list, (v) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: v.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, {
                    src: v.img
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("div", {
                        slot: "error",
                        class: "image-slot"
                      }, [
                        _createElementVNode("i", { class: "el-icon-picture-outline" })
                      ], -1)
                    ])),
                    _: 2
                  }, 1032, ["src"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: homeBackground.value.right
        }, null, 8, _hoisted_5)
      ])
    ])
  ]))
}
}

})