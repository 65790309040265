import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "real-person-box"
}
const _hoisted_2 = ["src"]

import { homeRealEvents } from "@/api/home-list"
import { ref } from "vue";
import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const router = useRouter()

const realPerson: any = ref({})

homeRealEvents().then(res => {
    const { data } = res
    realPerson.value = data
})


return (_ctx: any,_cache: any) => {
  return (realPerson.value.img)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "real-into-box",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push(`/consultingServiceDetail/${realPerson.value.id}`)))
        }, [
          _createElementVNode("img", {
            src: realPerson.value.img
          }, null, 8, _hoisted_2)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})