import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "adapt-box curriculum-box" }
const _hoisted_2 = { class: "curriculum-into-box" }
const _hoisted_3 = { class: "header-container" }
const _hoisted_4 = { class: "certificate-display" }
const _hoisted_5 = { class: "certificate-into-box" }
const _hoisted_6 = { class: "left-box" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "right-box" }
const _hoisted_9 = { class: "one-block" }
const _hoisted_10 = { class: "curriculum-display" }
const _hoisted_11 = { class: "display-into-box" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "pane-item fix-pane-item" }
const _hoisted_14 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import HorizontalBar from "@/components/HorizontalBar/index.vue";
import { certificateDetail, selectListByCertId } from "@/api/curriculum-list"
import { CertificateDetailInterface } from "@/types/itemIndex"
import { HorizontalBarInterface } from "@/types/index"
import { useRoute } from 'vue-router'
import { reactive } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute()

let certificateDetailInfo: CertificateDetailInterface = reactive({
    data: {}
})
certificateDetail({ id: String(route.params.id) }).then(res => {
    const data = res.data;
    certificateDetailInfo.data = data
})

let horizontalBar: HorizontalBarInterface = reactive({
    list: [],
    isRequireAccount: true
})
selectListByCertId({ certId: String(route.params.id) }).then(res => {
    const data = res.data;
    horizontalBar.list = data
})


return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(Header)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("img", {
            src: _unref(certificateDetailInfo).data.img
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            _createElementVNode("span", null, _toDisplayString(_unref(certificateDetailInfo).data.name), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", null, _toDisplayString(_unref(certificateDetailInfo).data.synopsis), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("span", null, "已报名" + _toDisplayString(_unref(certificateDetailInfo).data.enrollNumber), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_el_tabs, { type: "card" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, { label: "证书详情" }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _unref(certificateDetailInfo).data.detail,
                  class: "pane-item"
                }, null, 8, _hoisted_12)
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "相关课程" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(HorizontalBar, { horizontalBar: _unref(horizontalBar) }, null, 8, ["horizontalBar"]),
                  (_unref(horizontalBar).list.length == 0)
                    ? (_openBlock(), _createBlock(_component_el_empty, {
                        key: 0,
                        "image-size": 100,
                        image: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fb209937dc2276a7cb5a47fb831879af25c52fa1b25567-NGX9jR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642128140&t=009a26e4722b76fdd40bb57359b521ef"
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(Footer)
    ])
  ]))
}
}

})