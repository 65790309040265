import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-certificate" }
const _hoisted_2 = { class: "content-box" }
const _hoisted_3 = { class: "content-switch" }
const _hoisted_4 = { class: "content-already-box" }
const _hoisted_5 = { class: "content-item-box" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "left-box" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "right-box" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "time" }
const _hoisted_12 = { class: "no-already-box" }
const _hoisted_13 = { class: "content-item-box" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "left-box" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "right-box" }
const _hoisted_18 = { class: "title" }
const _hoisted_19 = { class: "time" }
const _hoisted_20 = { class: "no-already-box" }
const _hoisted_21 = { class: "content-item-box" }
const _hoisted_22 = { class: "left-box" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "right-box" }
const _hoisted_25 = { class: "title" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "time" }

import { ref } from "vue";
import {
  getMyBuyExaminationList,
  selectManualRecordByUserIdList,
} from "@/api/examination-list";
import AlibabaCloudUpload from "@/components/AlibabaCloudUpload/index.vue";
import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const router = useRouter();

let noComplete: any = ref([]);
let alreadyComplete: any = ref([]);
let operationRecordList: any = ref([]);

getMyBuyExaminationList().then((res) => {
  const { data } = res;
  noComplete.value = data.notSubmitExamList;
  alreadyComplete.value = data.submitExamList;
});

selectManualRecordByUserIdList().then((res) => {
  const data = res.data;
  operationRecordList.value = data;
});

const goCertificateDetail = (id: number) => {
  router.push(`/certificateDetail/${id}`);
};

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "header-box" }, [
      _createElementVNode("span", null, "我的考试")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_tabs, { type: "card" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, { label: "正式考试" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", null, [
                    _createElementVNode("span", null, "未完成")
                  ], -1)),
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(noComplete), (v) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: v.id,
                        class: "content-item",
                        onClick: ($event: any) => (_unref(router).push(`/examinationDetail/${v.id}`))
                      }, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("img", {
                            src: v.coverImg
                          }, null, 8, _hoisted_8)
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_10, _toDisplayString(v.name), 1),
                            _createElementVNode("span", _hoisted_11, _toDisplayString(v.releaseTime), 1)
                          ]),
                          _createElementVNode("div", null, [
                            _createVNode(_component_el_button, { size: "small" }, {
                              default: _withCtx(() => _cache[0] || (_cache[0] = [
                                _createTextVNode("开始考试")
                              ])),
                              _: 1
                            })
                          ])
                        ])
                      ], 8, _hoisted_6))
                    }), 128)),
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fix-space-self" }, null, -1)),
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fix-space-self" }, null, -1))
                  ])
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "black-line" }, null, -1)),
                _createElementVNode("div", _hoisted_12, [
                  _cache[6] || (_cache[6] = _createElementVNode("div", null, [
                    _createElementVNode("span", null, "已完成")
                  ], -1)),
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(alreadyComplete), (v) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: v.id,
                        class: "content-item",
                        onClick: ($event: any) => (_unref(router).push(`/doneExamination/${v.recordId}/1`))
                      }, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("img", {
                            src: v.coverImg
                          }, null, 8, _hoisted_16)
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_18, _toDisplayString(v.name), 1),
                            _createElementVNode("span", _hoisted_19, _toDisplayString(v.releaseTime), 1)
                          ]),
                          _createElementVNode("div", null, [
                            _createVNode(_component_el_button, {
                              size: "small",
                              style: {"background":"rgb(184, 181, 181)"}
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(v.isReview == 1 ? "已批阅" : "批阅中"), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ])
                      ], 8, _hoisted_14))
                    }), 128)),
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fix-space-self" }, null, -1)),
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "fix-space-self" }, null, -1))
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "实操考试" }, {
              default: _withCtx(() => [
                _createVNode(AlibabaCloudUpload),
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "black-line" }, null, -1)),
                _createElementVNode("div", _hoisted_20, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", null, [
                    _createElementVNode("span", null, "已完成")
                  ], -1)),
                  _createElementVNode("div", _hoisted_21, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(operationRecordList), (v) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: v.id,
                        class: "content-item"
                      }, [
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("img", {
                            src: v.poVideoCover
                          }, null, 8, _hoisted_23)
                        ]),
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_25, _toDisplayString(v.certName), 1),
                            (v.scores)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_26, "分数: " + _toDisplayString(v.scores), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", _hoisted_27, _toDisplayString(v.releaseTime), 1)
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("div", null, _toDisplayString(v.isReview == 2 ? "审批中" : "已审批"), 1)
                          ]),
                          _cache[8] || (_cache[8] = _createElementVNode("div", null, null, -1))
                        ])
                      ]))
                    }), 128)),
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "fix-space-self" }, null, -1)),
                    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "fix-space-self" }, null, -1))
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})