import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "content-box" }
const _hoisted_2 = { class: "module-board-data" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "board-data-display" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "board-data-bottom-border" }
const _hoisted_7 = { class: "board-data-title" }
const _hoisted_8 = { class: "board-data-introduce one-block" }

import { ref } from 'vue';
import { certificateSelectList } from "@/api/person-list"
import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const router = useRouter()
let myCourseList: any = ref([])

certificateSelectList().then(res => {
    const { data } = res;
    let temp: any = []
    data.forEach(v => {
        if (v.isBuy == 1) {
            temp.push(v)
        }
    });
    myCourseList.value = temp

})

const goCertificateDetail = (id: number) => {
    router.push(`/certificateDetail/${id}`)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "header-box" }, [
      _createElementVNode("span", null, "我的课程"),
      _createElementVNode("span", null, "||"),
      _createElementVNode("span", null, "直播回放")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(myCourseList), (v) => {
          return (_openBlock(), _createElementBlock("div", {
            key: v.id,
            class: "board-data-item",
            onClick: ($event: any) => (goCertificateDetail(v.id))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "data-display-img",
                src: v.img
              }, null, 8, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(v.name), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", null, _toDisplayString(v.synopsis), 1)
              ])
            ])
          ], 8, _hoisted_3))
        }), 128)),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "fix-space-self" }, null, -1)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fix-space-self" }, null, -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fix-space-self" }, null, -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fix-space-self" }, null, -1))
      ])
    ])
  ]))
}
}

})