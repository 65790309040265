import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "expert-detail-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "expert-box" }
const _hoisted_4 = { class: "expert-into-box" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "left-box" }
const _hoisted_7 = { class: "left-into-box" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "right-box" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { selectLecturerList } from "@/api/home-list";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const router = useRouter();

const allLecturerList: any = ref([]);
selectLecturerList().then((res) => {
  const data = res.data;
  allLecturerList.value = data;
});

const goExpertDetail = (id: number, type: number) => {
  router.push(`/expertDetail/${id}/${type}`);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allLecturerList.value, (v) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "expert-item",
            onClick: ($event: any) => (goExpertDetail(v.id, v.t))
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  src: v.img
                }, null, 8, _hoisted_8),
                _createElementVNode("span", null, _toDisplayString(v.name), 1),
                _createElementVNode("span", null, _toDisplayString(v.title), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", {
                class: "five-block",
                innerHTML: v.synopsis
              }, null, 8, _hoisted_10)
            ])
          ], 8, _hoisted_5))
        }), 256))
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(Footer)
    ])
  ]))
}
}

})