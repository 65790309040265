import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { useStore } from 'vuex'
import { computed } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useStore()
const getterInstance = computed(() => store.getters.user)

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (getterInstance.value.isRouterView)
    ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0,
        class: "global-route-style"
      }))
    : _createCommentVNode("", true)
}
}

})