import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/images/collection.png'
import _imports_1 from '../../assets/images/already-learn.png'


const _hoisted_1 = { class: "curriculum-item-box" }
const _hoisted_2 = { class: "curriculum-item" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "left-box" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "right-box" }
const _hoisted_7 = { class: "right-box-title" }
const _hoisted_8 = { class: "right-box-introduce two-block" }
const _hoisted_9 = { class: "right-box-bottom" }
const _hoisted_10 = { key: 0 }

import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    horizontalBar: {}
  },
  setup(__props: any) {

const props = __props
const router = useRouter()

const goCurriculumDetail = (id: number) => {
    router.push(`/curriculumDetail/${id}`)
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.horizontalBar.list, (v) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "curriculum-into-item",
          onClick: ($event: any) => (goCurriculumDetail(v.id))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: v.coverImg
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(v.name), 1)
            ]),
            _createElementVNode("div", _hoisted_8, _toDisplayString(v.synopsis), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(v.lecturerNames || v.synopsis), 1)
              ]),
              (props.horizontalBar.isRequireAccount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", null, [
                      _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(v.collectionNum), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(v.browseNum), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 8, _hoisted_3)
      ]))
    }), 256))
  ]))
}
}

})