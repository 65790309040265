import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "examination-detail-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "examination-box" }
const _hoisted_4 = { class: "examination-into-box" }
const _hoisted_5 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import ModuleBoardExamination from "@/components/ModuleBoardExamination/index.vue";
import { reactive } from 'vue'
import { getMyBuyExaminationList } from "@/api/examination-list"

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

let selectExaminationList: any = reactive({
    noSubmitList: [],
    alreadySubmitList:[],
    isRequireHeader: false,
})
getMyBuyExaminationList().then(res => {
    const data = res.data;
    selectExaminationList.noSubmitList =  data.notSubmitExamList
    selectExaminationList.alreadySubmitList =  data.submitExamList
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "examination-top-box" }, [
        _createElementVNode("span", null, "我的考试")
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(ModuleBoardExamination, { certificateInfo: _unref(selectExaminationList) }, null, 8, ["certificateInfo"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(Footer)
    ])
  ]))
}
}

})