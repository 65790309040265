import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { AliRTS } from "aliyun-rts-sdk"


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

var aliRts:any = AliRTS.createClient();

const getStream = () => {
    var video:any = document.getElementById("J_prismPlayer_P")
    let info: any = navigator
    info.isReceiveVideo = true

    aliRts.isSupport(info).then((re: any) => {
        
        aliRts.subscribe("artc://live.zryyedu.com/education/536878144250970112?auth_key=1642571772-0-0-a0bbea2c7ae9c48264989baa2c420247").then((remoteStream: any) => {
            remoteStream.play(video);
        }).catch((err: any) => {
            console.log(err);
            // 订阅失败
        })

        aliRts.on("onError", (err: any) => {
            console.log(`errorCode: ${err.errorCode}`);
            console.log(`message: ${err.message}`);
        })
    }).catch((err: any) => {
        // 不可用
        console.log(`not support errorCode: ${err.errorCode}`);
        console.log(`not support message: ${err.message}`);
    })

}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[1] || (_cache[1] = _createElementVNode("video", { id: "J_prismPlayer_P" }, null, -1)),
    _createVNode(_component_el_button, {
      type: "primary",
      onClick: getStream
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("开始观看")
      ])),
      _: 1
    })
  ]))
}
}

})