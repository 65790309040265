import axios from "@/utils/axios";

export const selectPcRotationList = () =>
  axios.get("/api/course/rotation/chart/selectPcRotationList");
export const selectCertificateList = () =>
  axios.get("/api/course/certificate/selectList");
export const selectLecturerList = () =>
  axios.get("/api/course/lecturer/selectList");
export const homePcList = () => axios.get("/api/course/information/homePcList");
export const homeRealEvents = () =>
  axios.get("/api/course/information/homeRealEvents");
export const selectBean = () =>
  axios.get("api/course/background/map/selectBean");
export const selectBeanBySpaceId = (data: any) =>
  axios.get("/api/course/advertising/selectBeanBySpaceId", { params: data });
// export const getAgreement = (data: any) => axios.get("/app/insure/agreement/getBeanByType", {params: data});

// export const submitInsured = (data: any) => axios.post("/app/insure/order/createOrder", data);
