import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue'




export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "header-box" }, [
      _createElementVNode("span", null, "优惠卷")
    ], -1),
    _createElementVNode("div", { class: "content-box" }, "1", -1)
  ])))
}
}

})