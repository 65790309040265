import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "expert-detail-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "expert-box" }
const _hoisted_4 = { class: "expert-into-box" }
const _hoisted_5 = { class: "expert-title-box" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "expert-title-name" }
const _hoisted_8 = { class: "expert-title-positon" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { lecturerDetail } from "@/api/expert-detail-list"

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

let expertDetailInfo: any = ref({})
const route = useRoute()
lecturerDetail({ id: String(route.params.id), t: Number(route.params.type) }).then(res => {
    const data = res.data;
    data.alreadyLoad = true;
    expertDetailInfo.value = data
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("img", {
            src: _unref(expertDetailInfo).img
          }, null, 8, _hoisted_6), [
            [_vShow, _unref(expertDetailInfo).alreadyLoad]
          ]),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(expertDetailInfo).name), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(expertDetailInfo).title), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "expert-line-box" }, null, -1)),
        _createElementVNode("div", {
          class: "expert-content-box",
          innerHTML: _unref(expertDetailInfo).synopsis
        }, null, 8, _hoisted_9)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(Footer)
    ])
  ]))
}
}

})