import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/consulting-bg.png'


const _hoisted_1 = { class: "adapt-box Consulting-box" }
const _hoisted_2 = { class: "Consulting-into-box" }
const _hoisted_3 = { class: "header-container" }
const _hoisted_4 = { class: "certificate-display" }
const _hoisted_5 = { class: "certificate-into-box" }
const _hoisted_6 = { class: "certificate-content-box" }
const _hoisted_7 = { class: "certificate-content-title" }
const _hoisted_8 = { class: "certificate-content-flag" }
const _hoisted_9 = { class: "certificate-content-content" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import { useRoute } from 'vue-router'
import { informationDetail } from "@/api/consulting-detail"
import { reactive, ref, toRefs } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute()

let informationDetailInfo: any = reactive({
    data: {}
})


informationDetail({ id: route.params.id }).then(res => {
    const { data } = res
    informationDetailInfo.data = data
})




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(Header)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[4] || (_cache[4] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(_unref(informationDetailInfo).data.name), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", null, _toDisplayString(_unref(informationDetailInfo).data.releaseTime), 1),
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "来源:", -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(informationDetailInfo).data.source), 1),
            _cache[1] || (_cache[1] = _createElementVNode("span", null, "作者:", -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(informationDetailInfo).data.author), 1),
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "查看:", -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(informationDetailInfo).data.clickNumber), 1)
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "certificate-line" }, null, -1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", {
              innerHTML: _unref(informationDetailInfo).data.data
            }, null, 8, _hoisted_10)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(Footer)
    ])
  ]))
}
}

})