import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createBlock as _createBlock, isRef as _isRef } from "vue"
import _imports_0 from '../../assets/images/collection.png'
import _imports_1 from '../../assets/images/already-select.png'
import _imports_2 from '../../assets/images/already-learn.png'
import _imports_3 from '../../assets/images/already-lock.png'
import _imports_4 from '../../assets/images/already-un-lock.png'


const _hoisted_1 = { class: "curriculum-detail-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "curriculum-box" }
const _hoisted_4 = { class: "curriculum-into-box" }
const _hoisted_5 = { class: "video-box" }
const _hoisted_6 = { class: "left-video" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  class: "prism-player",
  id: "J_prismPlayer"
}
const _hoisted_9 = { class: "right-video" }
const _hoisted_10 = { class: "video-name" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "video-display" }
const _hoisted_13 = { class: "left-box" }
const _hoisted_14 = { class: "right-box" }
const _hoisted_15 = { class: "switch-table-box" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "switch-catalogue-box" }
const _hoisted_18 = { class: "custom-node" }
const _hoisted_19 = {
  key: 0,
  class: "before-icon",
  src: _imports_3
}
const _hoisted_20 = {
  key: 1,
  class: "before-icon",
  src: _imports_4
}
const _hoisted_21 = {
  key: 0,
  class: "math-process"
}
const _hoisted_22 = {
  key: 1,
  class: "math-process"
}
const _hoisted_23 = { class: "switch-class-box" }
const _hoisted_24 = { class: "submit-class" }
const _hoisted_25 = { class: "footer-container" }
const _hoisted_26 = { class: "dialog-footer" }
const _hoisted_27 = ["innerHTML"]

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import { reactive, ref, onBeforeUnmount, computed } from "vue";
import { useRoute } from "vue-router";
import { getToken } from "@/utils/auth";
import {
  selectPcCourseDetailByCurrId,
  addCollectCourse,
  getPlayInfo,
  deleteCollectCourse,
  addCourseViewingRecords,
  insertTanPracticeAnswer,
  selectSubjectListByWareId,
  insertClassroomPracticeAnswer,
} from "@/api/curriculum-list";
import { ElMessageBox } from "element-plus";
import SubjectServerBoth from "@/components/SubjectServerBoth/index.vue";
import { useStore } from "vuex";
declare var Aliplayer: any;


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const store = useStore();

const getterInstance = computed(() => store.getters.user);

let courseDetailInfo: any = reactive({
  data: {},
});
const route = useRoute();

let alreadyPlay = ref(false);
let player: any = null;
// 记录

let recordProcess: any = {
  currId: undefined,
  courseId: undefined,
  chapId: undefined,
  nodeId: undefined,
  totalDuration: undefined,
  viewingDuration: undefined,
};

const getData = () => {
  selectPcCourseDetailByCurrId({ currId: String(route.params.id) }).then(
    (res) => {
      const data = res.data;
      courseDetailInfo.data = data;
      recordProcess.currId = courseDetailInfo.data.id;
    }
  );
};

getData();

const goCollection = (courseId: number, type: number) => {
  if (type == 2) {
    addCollectCourse({ courseId }).then((res) => {
      getData();
    });
  } else {
    deleteCollectCourse({ courseId }).then((res) => {
      getData();
    });
  }
};

const defaultProps = {
  children: "nodeVoList",
  label: "name",
};

let classroomPractice: any = ref([]);
let classDialogVisible: any = ref(false);
let isShowClass: any = ref(false);
let currentSubject: any = ref({});
let currentWareId: any;

const submitSingle = () => {
  insertTanPracticeAnswer({
    wareId: currentWareId,
    subjectId: currentSubject.value.id,
    answer: currentSubject.value.answer,
  }).then((res: any) => {
    const { data } = res;
    if (res.code == 200) {
      currentSubject.value.isCorrect = data.isCorrect;
      currentSubject.value.analysis = data.analysis;
      currentSubject.value.answer = data.answer;
      currentSubject.value.userAnswer = data.userAnswer;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        classDialogVisible.value = false;
        player.play();
      }, 2000);
    }
  });
};

// 图文数据

let imageTextInfo: any = ref({});
let imageTextInfoVisible: any = ref(false);

const handleNodeClick = async (dataItem: any, node: any) => {
  if (dataItem.hasOwnProperty("isLook")) {
    if (dataItem.isLook == 1) {
      console.log(dataItem);

      if (dataItem.videoid) {
        currentWareId = dataItem.id;
        getPlayInfo({
          currId: courseDetailInfo.data.id,
          videoId: dataItem.videoid,
          wareId: dataItem.id,
        }).then(async (res) => {
          const { data } = res;
          if (player) {
            console.log(99);
            recordProcess.viewingDuration = player.getCurrentTime();
            addCourseViewingRecords(recordProcess).then((res) => {
              getData();
            });
            player.dispose();
          }
          player = new Aliplayer(
            {
              id: "J_prismPlayer",
              // width: "500px",
              // height: "300px",
              autoplay: true,
              source: data.playInfoList[0].playURL,
              // defaultDefinition: "LD",
              // definition: "FD,LD"
            },
            function (player: any) {
              recordProcess.courseId = dataItem.id;
              recordProcess.viewingDuration = player.getCurrentTime();
              recordProcess.totalDuration = parseInt(
                data.playInfoList[0].duration
              );
              switch (node.parent.data.cnt) {
                case 1:
                  recordProcess.chapId = node.parent.data.id;
                  break;
                case 2:
                  recordProcess.nodeId = node.parent.data.id;
                  recordProcess.chapId = node.parent.data.parent.data.id;
                  break;
                default:
                  break;
              }
              selectSubjectListByWareId({ wareId: dataItem.id }).then((res) => {
                const { data } = res;
                data.forEach((element: any) => {
                  element.isComplete = false;
                });
                classroomPractice.value = data;
              });
            }
          );

          player.on("ready", () => {
            player.seek(dataItem.viewingDuration);
          });

          player.on("timeupdate", (time: any) => {
            classroomPractice.value.forEach((v: any) => {
              if (
                v.pointOfTime == Math.floor(player.getCurrentTime()) &&
                !v.isComplete
              ) {
                v.isComplete = true;
                currentSubject.value = v;
                classDialogVisible.value = true;
                player.pause();
              }
            });
            if (
              classroomPractice.value[classroomPractice.value.length - 1]
                .pointOfTime < Math.floor(player.getCurrentTime())
            ) {
              isShowClass.value = true;
            } else {
              isShowClass.value = false;
            }
          });

          alreadyPlay.value = true;
        });
      } else {
        imageTextInfoVisible.value = true;
        imageTextInfo.value = dataItem;
        console.log(dataItem);
      }
    } else {
      store.dispatch("updateLogin", true);
    }
  }
};

onBeforeUnmount(() => {
  console.log(player);

  if (player) {
    recordProcess.viewingDuration = player.getCurrentTime();
    addCourseViewingRecords(recordProcess).then((res) => {});
  }
});

const submitClass = () => {
  let temp: any = [];
  let isPass: boolean = true;

  classroomPractice.value.forEach((v: any) => {
    let tempThing: any = {};
    switch (v.type) {
      case "1":
        tempThing.subjectId = v.id;
        tempThing.answer = v.answer;
        temp.push(tempThing);
        if (v.answer == "") {
          isPass = false;
        }
        break;
      case "2":
        tempThing.subjectId = v.id;
        tempThing.answer = v.answer.join(",");
        temp.push(tempThing);
        v.answer.forEach((v: any) => {
          if (v.value == "") {
            isPass = false;
          }
        });
        break;
      case "4":
        tempThing.subjectId = v.id;
        tempThing.answer = v.blankList.map((maps: any) => maps.value).join(";");
        temp.push(tempThing);
        v.blankList.forEach((v: any) => {
          if (v.value == "") {
            isPass = false;
          }
        });
        break;
      default:
        tempThing.subjectId = v.id;
        tempThing.answer = v.answer;
        temp.push(tempThing);
        if (v.answer == "") {
          isPass = false;
        }
        break;
    }
  });

  if (isPass) {
    insertClassroomPracticeAnswer({
      wareId: currentWareId,
      answerDtoList: temp,
    }).then((res) => {
      const { data } = res;
      classroomPractice.value.forEach((v: any) => {
        data.forEach((element: any) => {
          if (v.id == element.subjectId) {
            v.isCorrect = element.isCorrect;
            v.analysis = element.analysis;
            v.answer = element.answer;
            v.userAnswer = element.userAnswer;
          }
        });
      });
    });
  } else {
    ElMessageBox.confirm("当前还有题目未做,是否提交", "提示", {
      confirmButtonText: "提交",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        insertClassroomPracticeAnswer({
          wareId: currentWareId,
          answerDtoList: temp,
        }).then((res) => {
          const { data } = res;
          classroomPractice.value.forEach((v: any) => {
            data.forEach((element: any) => {
              if (v.id == element.subjectId) {
                v.isCorrect = element.isCorrect;
                v.analysis = element.analysis;
                v.answer = element.answer;
                v.userAnswer = element.userAnswer;
              }
            });
          });
        });
      })
      .catch(() => {});
  }
};

return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("img", {
              src: _unref(courseDetailInfo).data.coverImg
            }, null, 8, _hoisted_7), [
              [_vShow, !_unref(alreadyPlay) && _unref(courseDetailInfo).data.coverImg]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_8, null, 512), [
              [_vShow, _unref(alreadyPlay)]
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(courseDetailInfo).data.name), 1),
            _createElementVNode("div", {
              class: "video-detail",
              innerHTML: _unref(courseDetailInfo).data.synopsis
            }, null, 8, _hoisted_11),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", null, _toDisplayString(_unref(courseDetailInfo).data.lecturerNames), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", null, [
                  (_unref(courseDetailInfo).data.isCollect == 2)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (
                      goCollection(
                        _unref(courseDetailInfo).data.id,
                        _unref(courseDetailInfo).data.isCollect
                      )
                    )),
                        src: _imports_0
                      }))
                    : (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        src: _imports_1,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (
                      goCollection(
                        _unref(courseDetailInfo).data.id,
                        _unref(courseDetailInfo).data.isCollect
                      )
                    ))
                      })),
                  _createElementVNode("span", null, _toDisplayString(_unref(courseDetailInfo).data.collectionNum), 1)
                ]),
                _createElementVNode("div", null, [
                  _cache[4] || (_cache[4] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
                  _createElementVNode("span", null, _toDisplayString(_unref(courseDetailInfo).data.browseNum), 1)
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_el_tabs, { type: "card" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, { label: "课程详情" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "switch-detail-box",
                    innerHTML: _unref(courseDetailInfo).data.detail
                  }, null, 8, _hoisted_16)
                ]),
                _: 1
              }),
              _createVNode(_component_el_tab_pane, { label: "课程目录" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_el_tree, {
                      data: _unref(courseDetailInfo).data.chapterVoList,
                      props: defaultProps,
                      onNodeClick: handleNodeClick,
                      "default-expand-all": ""
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", null, [
                            (scope.data.isLook == 2)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_19))
                              : (_openBlock(), _createElementBlock("img", _hoisted_20)),
                            _createElementVNode("span", {
                              class: _normalizeClass({ lastItem: scope.data.isLook, title: true })
                            }, _toDisplayString(scope.node.label), 3)
                          ]),
                          _createElementVNode("div", null, [
                            (scope.data.isFinish == 1 && scope.data.videoid)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_21, "已完成"))
                              : (
                            scope.data.isFinish == 2 &&
                            scope.data.videoid &&
                            _unref(getToken)() &&
                            getterInstance.value.user.realName
                          )
                                ? (_openBlock(), _createElementBlock("span", _hoisted_22, "已完成" + _toDisplayString(scope.data.durationPercentage) + "%", 1))
                                : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["data"])
                  ])
                ]),
                _: 1
              }),
              (_unref(isShowClass) != 0)
                ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                    key: 0,
                    label: "课堂练习"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_23, [
                        _createVNode(SubjectServerBoth, { subjectList: _unref(classroomPractice) }, null, 8, ["subjectList"])
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: submitClass
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("提交")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createVNode(Footer)
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _unref(classDialogVisible),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(classDialogVisible) ? (classDialogVisible).value = $event : classDialogVisible = $event)),
      title: "随堂练习",
      width: "30%",
      "close-on-press-escape": false,
      "close-on-click-modal": false,
      "show-close": false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_26, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: submitSingle
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("提交答案")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(SubjectServerBoth, {
            subjectList: [_unref(currentSubject)]
          }, null, 8, ["subjectList"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: _unref(imageTextInfoVisible),
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(imageTextInfoVisible) ? (imageTextInfoVisible).value = $event : imageTextInfoVisible = $event)),
      title: _unref(imageTextInfo).name,
      top: "2vh",
      width: "69.5%"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "picture-box",
          innerHTML: _unref(imageTextInfo).data
        }, null, 8, _hoisted_27)
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}
}

})