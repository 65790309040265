import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/certificate-display-banner.png'
import _imports_1 from '../../assets/images/clock-icon.png'
import _imports_2 from '../../assets/images/paper-icon.png'
import _imports_3 from '../../assets/images/all-count-icon.png'


const _hoisted_1 = { class: "adapt-box curriculum-box" }
const _hoisted_2 = { class: "curriculum-into-box" }
const _hoisted_3 = { class: "header-container" }
const _hoisted_4 = { class: "certificate-display" }
const _hoisted_5 = { class: "certificate-into-box" }
const _hoisted_6 = { class: "certificate-into-header" }
const _hoisted_7 = { class: "left-box" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "right-box" }
const _hoisted_10 = { class: "certificate-into-count" }
const _hoisted_11 = { class: "certificate-into-content" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import { selectTestPaperByExamId, selectIsCanRetest, selectFirstIsCanExam } from "@/api/examination-list"
import { useRoute, useRouter } from 'vue-router'
import { reactive } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute()
const router = useRouter()

const checkStatus = () => {
    if (testDetailInfo.data.isRetest == "1") {
        selectIsCanRetest({ recordId: testDetailInfo.data.recordId }).then(res => {
            const data: any = res
            if (data.code == 200) {
                router.push(`/doingExamination/${testDetailInfo.data.examId}/${testDetailInfo.data.isRetest}`)
            }
        })
    } else if (testDetailInfo.data.isRetest == "2") {
        selectFirstIsCanExam({ examId: testDetailInfo.data.examId }).then(res => {
            const data: any = res
            if (data.code == 200) {
                router.push(`/doingExamination/${testDetailInfo.data.examId}/${testDetailInfo.data.isRetest}`)
            }
        })
    } else {
        router.push(`/doingExamination/${testDetailInfo.data.examId}/${testDetailInfo.data.isRetest}`)
    }
}

let testDetailInfo: any = reactive({
    data: {}
})
selectTestPaperByExamId({ examId: String(route.params.id) }).then(res => {
    const data = res.data;
    testDetailInfo.data = data
})



return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(Header)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[7] || (_cache[7] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: _unref(testDetailInfo).data.coverImg
            }, null, 8, _hoisted_8)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_unref(testDetailInfo).data.name), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_unref(testDetailInfo).data.passScore) + "人参与", 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_el_button, {
                size: "small",
                onClick: checkStatus
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("开始考试")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", null, [
            _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(testDetailInfo).data.duration || "无限制"), 1),
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "考试时长(分钟)", -1))
          ]),
          _createElementVNode("div", null, [
            _cache[3] || (_cache[3] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(testDetailInfo).data.totalNumber), 1),
            _cache[4] || (_cache[4] = _createElementVNode("span", null, "题目数量(题)", -1))
          ]),
          _createElementVNode("div", null, [
            _cache[5] || (_cache[5] = _createElementVNode("img", { src: _imports_3 }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(testDetailInfo).data.totalScore), 1),
            _cache[6] || (_cache[6] = _createElementVNode("span", null, "考试总分(分)", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", {
            innerHTML: _unref(testDetailInfo).data.detail
          }, null, 8, _hoisted_12)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(Footer)
    ])
  ]))
}
}

})