import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "adapt-box consulting-service-box" }
const _hoisted_2 = { class: "consulting-info-box" }
const _hoisted_3 = { class: "consulting-item" }
const _hoisted_4 = { class: "consulting-item-title" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "service-content" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "fix-span" }
const _hoisted_9 = { class: "consulting-item" }
const _hoisted_10 = { class: "consulting-item-title" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "service-content" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "fix-span" }
const _hoisted_15 = { class: "consulting-item fix-item" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "count-bottom-text" }

import { homePcList } from "@/api/home-list";
import CountSwiper from "@/components/CountSwiper/index.vue";
import { useRouter } from "vue-router";
import { reactive } from "vue";
import { Autoplay, Lazy } from "swiper";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const router = useRouter();

let modules = [Autoplay, Lazy];

let consultingServiceInfo: any = reactive({
  policyInformation: {},
  newsInformation: {},
  hotSpotFocus: {},
});

homePcList().then((res) => {
  const { data } = res;
  consultingServiceInfo.policyInformation = data[0];
  consultingServiceInfo.newsInformation = data[1];
  consultingServiceInfo.hotSpotFocus = data[2];
});

// const router = useRouter()

// const goCertificateDetail = (id: number) => {
//     router.push(`/certificateDetail/${id}`)
// }

return (_ctx: any,_cache: any) => {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_unref(consultingServiceInfo).policyInformation.name), 1),
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push(`/consulting`)))
          }, "更多>")
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "consulting-item-line" }, null, -1)),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(consultingServiceInfo).policyInformation.infoList, (v) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "consulting-item-content",
              key: v.id,
              onClick: ($event: any) => (_unref(router).push(`/consultingServiceDetail/${v.id}`))
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "red-item-point" }, null, -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", {
                  innerHTML: v.name
                }, null, 8, _hoisted_7)
              ]),
              _createElementVNode("div", _hoisted_8, _toDisplayString(v.releaseTime), 1)
            ], 8, _hoisted_5))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("span", null, _toDisplayString(_unref(consultingServiceInfo).newsInformation.name), 1),
          _createElementVNode("span", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push(`/consulting`)))
          }, "更多>")
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "consulting-item-line" }, null, -1)),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(consultingServiceInfo).newsInformation.infoList, (v) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "consulting-item-content",
              key: v.id,
              onClick: ($event: any) => (_unref(router).push(`/consultingServiceDetail/${v.id}`))
            }, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "red-item-point" }, null, -1)),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("span", {
                  innerHTML: v.name
                }, null, 8, _hoisted_13)
              ]),
              _createElementVNode("div", _hoisted_14, _toDisplayString(v.releaseTime), 1)
            ], 8, _hoisted_11))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_swiper, {
          "slides-per-view": 1,
          modules: _unref(modules),
          autoplay: "",
          lazy: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(consultingServiceInfo).hotSpotFocus.infoList, (v, i) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: v.id
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "count-swiper-item",
                    onClick: ($event: any) => (_unref(router).push(`/consultingServiceDetail/${v.id}`))
                  }, [
                    _createElementVNode("img", {
                      src: v.img
                    }, null, 8, _hoisted_17),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("span", null, _toDisplayString(v.name), 1)
                    ])
                  ], 8, _hoisted_16)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modules"])
      ])
    ])
  ]))
}
}

})