import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "subject-box" }
const _hoisted_2 = { class: "subject-title-box" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "choose-item-box"
}
const _hoisted_6 = { class: "choose-item" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 2,
  class: "choose-item-box"
}
const _hoisted_9 = { class: "choose-item" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 3,
  class: "choose-item-box"
}
const _hoisted_12 = {
  key: 4,
  class: "choose-item-box"
}
const _hoisted_13 = {
  key: 5,
  class: "choose-item-box"
}
const _hoisted_14 = { key: 6 }
const _hoisted_15 = {
  key: 0,
  class: "right-answer"
}
const _hoisted_16 = {
  key: 1,
  class: "error-answer"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    subjectList: {}
  },
  setup(__props: any) {

const props = __props




return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.subjectList, (v) => {
      return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(v.name), 1),
        (v.img)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("img", {
                class: "tip-img",
                src: v.img
              }, null, 8, _hoisted_4)
            ]))
          : _createCommentVNode("", true),
        (v.type == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.optionList, (options) => {
                return (_openBlock(), _createBlock(_component_el_radio, {
                  label: options.name,
                  modelValue: v.answer,
                  "onUpdate:modelValue": ($event: any) => ((v.answer) = $event)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", null, _toDisplayString(options.data), 1),
                      (options.img)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "tip-img",
                            src: v.img
                          }, null, 8, _hoisted_7))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1032, ["label", "modelValue", "onUpdate:modelValue"]))
              }), 256))
            ]))
          : (v.type == 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_el_checkbox_group, {
                  modelValue: v.answer,
                  "onUpdate:modelValue": ($event: any) => ((v.answer) = $event)
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.optionList, (options) => {
                      return (_openBlock(), _createBlock(_component_el_checkbox, {
                        label: options.name
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("span", null, _toDisplayString(options.data), 1),
                            (options.img)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  class: "tip-img",
                                  src: v.img
                                }, null, 8, _hoisted_10))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["label"]))
                    }), 256))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"])
              ]))
            : (v.type == 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_el_radio, {
                    modelValue: v.answer,
                    "onUpdate:modelValue": ($event: any) => ((v.answer) = $event),
                    label: "正确"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("正确")
                    ])),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_el_radio, {
                    modelValue: v.answer,
                    "onUpdate:modelValue": ($event: any) => ((v.answer) = $event),
                    label: "错误"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("错误")
                    ])),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"])
                ]))
              : (v.type == 4)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.blankList, (blanks) => {
                      return (_openBlock(), _createBlock(_component_el_input, {
                        class: "blank-item",
                        modelValue: blanks.value,
                        "onUpdate:modelValue": ($event: any) => ((blanks.value) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                    }), 256))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_el_input, {
                      modelValue: v.answer,
                      "onUpdate:modelValue": ($event: any) => ((v.answer) = $event),
                      rows: 5,
                      type: "textarea",
                      placeholder: "Please input"
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ])),
        (v.isCorrect)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              (v.isCorrect == 1)
                ? (_openBlock(), _createElementBlock("p", _hoisted_15, "回答正确"))
                : (_openBlock(), _createElementBlock("p", _hoisted_16, "回答错误")),
              _createElementVNode("p", null, [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "answer-shadow" }, "正确答案", -1)),
                _createTextVNode(" " + _toDisplayString(v.answer), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "answer-shadow" }, "用户答案", -1)),
                _createTextVNode(" " + _toDisplayString(v.userAnswer), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "analysis" }, "解析", -1)),
                _createTextVNode(" " + _toDisplayString(v.analysis), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 256))
  ]))
}
}

})