import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "consulting-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "consulting-box" }
const _hoisted_4 = { class: "consulting-into-box" }
const _hoisted_5 = { class: "consulting-left-box" }
const _hoisted_6 = { class: "left-content-box" }
const _hoisted_7 = { class: "content-item-box" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "content-item-left" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "content-item-right" }
const _hoisted_12 = { class: "one-block" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "left-switch-page" }
const _hoisted_15 = { class: "demo-pagination-block" }
const _hoisted_16 = { class: "consulting-right-box" }
const _hoisted_17 = { class: "consulting-right-title" }
const _hoisted_18 = { class: "content-item-box" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "content-item-left" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "content-item-right" }
const _hoisted_23 = { class: "one-block right-span" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import {
  selectClassifyList,
  listByPageInformation,
  selectHotspotList,
} from "@/api/consulting-detail";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const router = useRouter();
let consultingClassList: any = reactive({ data: [] });
let consultingList: any = reactive({ data: [] });
let page = ref(1);
let limit = 6;
let total = ref(0);
let classId = 0;

const getData = () => {
  listByPageInformation({
    page: page.value,
    limit: limit,
    classId: classId,
  }).then((res) => {
    const data = res.data;
    consultingList.data = [...data.data];
    total.value = data.total / limit;
  });
};

// const route = useRoute()
selectClassifyList().then((res) => {
  const data = res.data;
  consultingClassList.data = data;
  classId = data[0].id;
  getData();
});

const switchConsulting = (item: any, event: any) => {
  classId = item.instance.attrs.id;
  getData();
};

const handleCurrentChange = (val: any) => {
  page.value = val;
  getData();
};

let hotPage = 1;
let hotLimit = 99;
let hotTitle = ref("");
let hotList: any = reactive({
  data: [],
});

const getHotData = () => {
  selectHotspotList({
    page: hotPage,
    limit: hotLimit,
  }).then((res) => {
    const data = res.data;
    hotTitle.value = data.className;
    hotList.data = data.infoList.data;
  });
};

getHotData();

return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_tabs, {
            type: "card",
            onTabClick: switchConsulting
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(consultingClassList).data, (v) => {
                return (_openBlock(), _createBlock(_component_el_tab_pane, {
                  label: v.name,
                  id: v.id
                }, null, 8, ["label", "id"]))
              }), 256))
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(consultingList).data, (v) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "content-item",
                  onClick: ($event: any) => (_unref(router).push(`/consultingServiceDetail/${v.id}`))
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("img", {
                      src: v.img
                    }, null, 8, _hoisted_10)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("span", _hoisted_12, _toDisplayString(v.name), 1),
                    _createElementVNode("span", {
                      innerHTML: v.name
                    }, null, 8, _hoisted_13)
                  ])
                ], 8, _hoisted_8))
              }), 256)),
              (_unref(consultingList).data.length == 0)
                ? (_openBlock(), _createBlock(_component_el_empty, {
                    key: 0,
                    "image-size": 100,
                    image: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fb209937dc2276a7cb5a47fb831879af25c52fa1b25567-NGX9jR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642128140&t=009a26e4722b76fdd40bb57359b521ef"
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_el_pagination, {
                "page-size": 100,
                background: "",
                "prev-text": "上一页",
                "next-text": "下一页",
                "jumper-text": "跳转",
                layout: "prev, pager, next, jumper",
                total: _unref(total) * 100,
                onCurrentChange: handleCurrentChange
              }, null, 8, ["total"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("span", null, _toDisplayString(_unref(hotTitle)), 1)
          ]),
          _createElementVNode("div", _hoisted_18, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(hotList).data, (v) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "content-item",
                onClick: ($event: any) => (_unref(router).push(`/consultingServiceDetail/${v.id}`))
              }, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("img", {
                    src: v.img
                  }, null, 8, _hoisted_21)
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("span", _hoisted_23, _toDisplayString(v.name), 1),
                  _createElementVNode("span", {
                    innerHTML: v.name
                  }, null, 8, _hoisted_24)
                ])
              ], 8, _hoisted_19))
            }), 256))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createVNode(Footer)
    ])
  ]))
}
}

})