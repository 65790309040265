import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../../assets/images/person-post.png'


const _hoisted_1 = { class: "content-box" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "person-avatar",
  src: _imports_0
}
const _hoisted_4 = { class: "no-input" }
const _hoisted_5 = { class: "no-input" }
const _hoisted_6 = { class: "no-input" }
const _hoisted_7 = { class: "no-input" }
const _hoisted_8 = { class: "no-input" }
const _hoisted_9 = { class: "submit-box" }

import { ref } from "vue";
import { userDetail, updateInfo } from "@/api/person-list";
import { getToken } from "@/utils/auth";
import { ElNotification } from "element-plus";
import axios from "@/utils/axios";

// 个人信息

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

let personUserForm: any = ref({});

// 上传
const uploadFile = (files: any) => {
  var formData = new FormData();
  formData.append("files[]", files.file);
  axios
    .post("https://php.zryyedu.com/api/upload/batchUpload", formData)
    .then((res) => {
      personUserForm.value.headPortrait = res.data[0].file_url;
    });
};

userDetail().then((res) => {
  const { data } = res;
  personUserForm.value = data;
});

const savePersonInfo = () => {
  updateInfo(personUserForm.value).then((res) => {
    let data: any = res;
    ElNotification({
      title: "提示",
      type: "info",
      message: data.msg,
      position: "top-right",
      offset: 100,
    });
  });
};

return (_ctx: any,_cache: any) => {
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "header-box" }, [
      _createElementVNode("span", null, "个人资料")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_form, {
        ref: "personUserRef",
        model: _unref(personUserForm),
        "label-width": "120px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "头像" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_upload, {
                action: "https://gateway.gjyunying.org.cn/api/user/upload/oss/uploadFile",
                "http-request": uploadFile,
                "show-file-list": false
              }, {
                default: _withCtx(() => [
                  (_unref(personUserForm).headPortrait)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "person-avatar",
                        src: _unref(personUserForm).headPortrait
                      }, null, 8, _hoisted_2))
                    : (_openBlock(), _createElementBlock("img", _hoisted_3))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "昵称:" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_el_input, {
                  modelValue: _unref(personUserForm).nickname,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(personUserForm).nickname) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "真实姓名:" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(personUserForm).realName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "手机号:" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(personUserForm).mobile), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "身份证号:" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(personUserForm).idCard), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "性别:" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio, {
                modelValue: _unref(personUserForm).gender,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(personUserForm).gender) = $event)),
                label: 1
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("男")
                ])),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_radio, {
                modelValue: _unref(personUserForm).gender,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(personUserForm).gender) = $event)),
                label: 2
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("女")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "地址:" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_el_input, {
                  modelValue: _unref(personUserForm).address,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(personUserForm).address) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_el_button, { onClick: savePersonInfo }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("保存")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})