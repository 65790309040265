import axios from "@/utils/axios";

export const getMyBuyExaminationList = () =>
  axios.get(`/api/exam/examination/selectExaminationList`);
export const selectTestPaperByExamId = (data: any) =>
  axios.get(`/api/exam/test/paper/selectTestPaperByExamId`, { params: data });
export const selectSubjectListByTestId = (data: any) =>
  axios.get(`/api/exam/subject/selectSubjectListByTestId`, { params: data });
export const insertFirstExam = (data: any) =>
  axios.post(`/api/exam/subject/insertFirstExam`, data);
export const insertRetestExam = (data: any) =>
  axios.post(`/api/exam/subject/insertRetestExam`, data);
export const selectIsCanRetest = (data: any) =>
  axios.get(`/api/exam/examination/selectIsCanRetest`, { params: data });
export const selectFirstIsCanExam = (data: any) =>
  axios.get(`/api/exam/examination/selectFirstIsCanExam`, { params: data });
export const selectExamDetail = (data: any) =>
  axios.get(`/api/exam/examination/selectExamDetail`, { params: data });
export const insertMockExam = (data: any) =>
  axios.post(`/api/exam/subject/insertMockExam`, data);
export const selectManualRecordByUserIdList = () =>
  axios.get(`/api/exam/examination/selectManualRecordByUserIdList`);
export const selectUserCertOperationList = () =>
  axios.get(`/api/exam/examination/selectUserCertOperationList`);
export const selectMockExamDetail = (data: any) =>
  axios.get(`/api/exam/mock/examination/record/selectMockExamDetail`, {
    params: data,
  });
