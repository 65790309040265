import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "alibaba-cloud-upload" }
const _hoisted_2 = { class: "header-box" }
const _hoisted_3 = { class: "up-load-box" }

import { ref } from "vue";
import axios from "@/utils/axios";
import { selectCertificateList } from "@/api/home-list";
import { selectUserCertOperationList } from "@/api/examination-list";
import { ElNotification } from "element-plus";

declare var AliyunUpload: any;

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

let fileList: any = ref([]);
let certificateOptions: any = ref([]);
let certificateValue: any = ref("");
let operationOptions: any = ref([]);
let operationValue: any = ref("");

selectUserCertOperationList().then((res: any) => {
  const data = res.data;
  certificateOptions.value = data;
});

const certificateChange = (value: any) => {
  operationOptions.value = value.practicalOperationList;
  operationValue.value = "";
};

let uploader: any = null;
let authProgress: any = null;
let statusText: any = null;
let uploadDisabled: any = null;
let resumeDisabled: any = null;
let pauseDisabled: any = null;

const myUpload = (content: any) => {
  fileList.value = [content.file];
  ElNotification({
    title: "提示",
    type: "info",
    message: "选择完成请点击提交",
    position: "top-right",
    offset: 100,
  });
};

const createUploader = () => {
  let uploader = new AliyunUpload.Vod({
    timeout: 60000,
    partSize: 1048576,
    parallel: 5,
    retryCount: 3,
    retryDuration: 2,
    region: "cn-shanghai",
    userId: "8888888",
    // 添加文件成功
    addFileSuccess: function (uploadInfo: any) {
      uploadDisabled = false;
      resumeDisabled = false;
      statusText = "添加文件成功, 等待上传...";
      console.log("addFileSuccess: " + uploadInfo.file.name);
    },
    // 开始上传
    onUploadstarted: function (uploadInfo: any) {
      if (!uploadInfo.videoId) {
        console.log("无id");
        let createUrl = "/api/course/vod/getCertAndAddress";
        axios
          .get(createUrl, {
            params: {
              title: uploadInfo.file.name,
              fileName: uploadInfo.file.name,
            },
          })
          .then((res) => {
            const { data } = res;
            let uploadAuth = data.uploadAuth;
            let uploadAddress = data.uploadAddress;
            let videoId = data.videoId;
            uploader.setUploadAuthAndAddress(
              uploadInfo,
              uploadAuth,
              uploadAddress,
              videoId
            );
          });
      } else {
        console.log("有id");
        let refreshUrl = "/api/course/vod/refreshCertAndAddress";
        axios
          .get(refreshUrl, {
            params: {
              videoId: uploadInfo.videoId,
              title: uploadInfo.file.name,
              fileName: uploadInfo.file.name,
            },
          })
          .then((res) => {
            const { data } = res;
            let uploadAuth = data.uploadAuth;
            let uploadAddress = data.uploadAddress;
            let videoId = data.videoId;
            uploader.setUploadAuthAndAddress(
              uploadInfo,
              uploadAuth,
              uploadAddress,
              videoId
            );
          });
      }
    },
    // 文件上传成功
    onUploadSucceed: function (uploadInfo: any) {
      statusText = "文件上传成功!";
      console.log(uploadInfo, "success");

      axios
        .post("/api/exam/examination/insertOperation", {
          poVideoId: uploadInfo.videoId,
          certId: certificateValue.value.certId,
          poId: operationValue.value,
        })
        .then((res) => {
          const { data } = res;
          console.log(data);
          ElNotification({
            title: "提示",
            type: "info",
            message: "提交成功",
            position: "top-right",
            offset: 100,
          });
        });
    },
    // 文件上传失败
    onUploadFailed: function (uploadInfo: any, code: any, message: any) {
      statusText = "文件上传失败!";
    },
    // 取消文件上传
    onUploadCanceled: function (uploadInfo: any, code: any, message: any) {
      statusText = "文件已暂停上传";
    },
    // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
    onUploadProgress: function (
      uploadInfo: any,
      totalSize: any,
      progress: any
    ) {},
    // 上传凭证超时
    onUploadTokenExpired: function (uploadInfo: any) {
      console.log("上传凭证失效");
    },
    // 全部文件上传结束
    onUploadEnd: function (uploadInfo: any) {},
  });
  return uploader;
};

const authUpload = () => {
  // 然后调用 startUpload 方法, 开始上传
  if (uploader !== null) {
    uploader.startUpload();
    uploadDisabled = true;
    pauseDisabled = false;
  }
};
// 暂停上传
const pauseUpload = () => {
  if (uploader !== null) {
    uploader.stopUpload();
    resumeDisabled = false;
    pauseDisabled = true;
  }
};
// 恢复上传
const resumeUpload = () => {
  if (uploader !== null) {
    uploader.startUpload();
    resumeDisabled = true;
    pauseDisabled = false;
  }
};

const goUpload = () => {
  if (fileList.length == 0) {
    alert("请选择文件");
    return false;
  }
  if(fileList._rawValue[0].type!='video/mp4'){
    //上传非视频
    const params = new FormData();
    fileList.value.forEach((v: any, i: number) => {
      let params = new FormData();
      params.append(`files[]`, v);
      axios.post("https://php.zryyedu.com/api/upload/batchUpload", params).then((res) => {
        const { data } = res;
        if(res.code==200){
          statusText = "文件上传成功!"; 
          axios
        .post("/api/exam/examination/insertOperation", {
          certId: certificateValue.value.certId,
          poId: operationValue.value,
          poVideoId: data[0].file_url,
        })
        .then((res) => {
          const { data } = res;
          console.log(data);
          ElNotification({
            title: "提示",
            type: "info",
            message: "提交成功",
            position: "top-right",
            offset: 100,
          });
        });
        }      
      });
    });
  }else{
    var userData = '{"Vod":{}}';
  if (uploader) {
    uploader.stopUpload();
    authProgress = 0;
    statusText = "";
  }

  uploader = createUploader();
  fileList.value.forEach((v: any, i: number) => {
    uploader.addFile(v, null, null, null, userData);
  });

  uploadDisabled = false;
  pauseDisabled = true;
  resumeDisabled = true;
  authUpload();
  }
};

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_el_select, {
          modelValue: _unref(certificateValue),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(certificateValue) ? (certificateValue).value = $event : certificateValue = $event)),
          "value-key": "certId",
          placeholder: "选择证书",
          onChange: certificateChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(certificateOptions), (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.certId,
                label: item.certName,
                value: item
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_el_select, {
          modelValue: _unref(operationValue),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(operationValue) ? (operationValue).value = $event : operationValue = $event)),
          placeholder: "选择动作"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(operationOptions), (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_el_upload, {
          action: "",
          "show-file-list": false,
          "http-request": myUpload
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_button, { type: "primary" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("上传动作")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: goUpload
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("提交")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})