import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "top-background" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "swiper-container" }
const _hoisted_6 = { class: "module-board-container" }
const _hoisted_7 = { class: "real-person-container" }
const _hoisted_8 = { class: "consulting-service-container" }
const _hoisted_9 = { class: "count-swiper-container" }
const _hoisted_10 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Swiper from "@/components/Swiper/index.vue";
import ModuleBoard from "@/components/ModuleBoard/index.vue";
import RealPerson from "@/components/RealPerson/index.vue";
import ConsultingService from "@/components/ConsultingService/index.vue";
import CountSwiper from "@/components/CountSwiper/index.vue";
import Footer from "@/components/Footer/index.vue";
import { reactive, ref } from 'vue'
import { SwiperInfoInterface, CertificateInfoInterface, lecturerInfoInterface } from "@/types/index"

import { selectPcRotationList, selectCertificateList, selectLecturerList, selectBean } from "@/api/home-list"


// 轮播图

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const swiperInfo: SwiperInfoInterface = reactive({
  list: []
})
selectPcRotationList().then(res => {
  const { data } = res;
  swiperInfo.list = data
})

// 证书
const certificateInfo: CertificateInfoInterface = reactive({
  isRequireHeader: true,
  list: []
})
selectCertificateList().then(res => {
  const { data } = res;
  certificateInfo.list = data.slice(0, 10)
})

// 专家和讲师
const lecturerInfo: lecturerInfoInterface = reactive({
  list: []
})
selectLecturerList().then(res => {
  const { data } = res;
  lecturerInfo.list = data
})

// 首页背景图
const homeBackground: any = ref({
  top: "",
})

selectBean().then(res => {
  const { data } = res;
  homeBackground.value.top = data.img
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: homeBackground.value.top
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(Swiper, { swiperInfo: swiperInfo }, null, 8, ["swiperInfo"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(ModuleBoard, { certificateInfo: certificateInfo }, null, 8, ["certificateInfo"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(RealPerson)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(ConsultingService)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(CountSwiper, { lecturerInfo: lecturerInfo }, null, 8, ["lecturerInfo"])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(Footer)
    ])
  ]))
}
}

})