import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/query-left-bg.png'


const _hoisted_1 = { class: "adapt-box query-box" }
const _hoisted_2 = { class: "query-into-box" }
const _hoisted_3 = { class: "header-container" }
const _hoisted_4 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(Header)
      ])
    ]),
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"content-up-box\" data-v-40fff60a><div class=\"content-box\" data-v-40fff60a><div class=\"show-box\" data-v-40fff60a><div class=\"left\" data-v-40fff60a><img src=\"" + _imports_0 + "\" data-v-40fff60a></div><div class=\"right\" data-v-40fff60a></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(Footer)
    ])
  ]))
}
}

})