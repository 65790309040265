import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "content-box" }
const _hoisted_2 = { class: "content-switch" }
const _hoisted_3 = { class: "choose-curriculum-box" }
const _hoisted_4 = { class: "content-item-box" }
const _hoisted_5 = { class: "left-box" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "right-box" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "time" }
const _hoisted_10 = { class: "content-item-box" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "left-box" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "right-box" }
const _hoisted_15 = { class: "title" }
const _hoisted_16 = { class: "time" }
const _hoisted_17 = { class: "choose-curriculum-box" }
const _hoisted_18 = { class: "error-box" }
const _hoisted_19 = { class: "page-box" }

import { ref } from "vue";
import {
  selectMyCourseList,
  selectMockExamList,
  selectMyErrorQuestionList,
  selectMockExamRecordList,
} from "@/api/person-list";
import SubjectServerDetail from "@/components/SubjectServerDetail/index.vue";
import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const router = useRouter();

let curriculumValue = ref("");
let curriculumOptions: any = ref([]);
let curriculumMockExamList: any = ref([]);
let curriculumMockExamRecord: any = ref([]);
// 模拟考试
selectMyCourseList().then((res) => {
  const data = res;
  curriculumOptions.value = data.data;
  let defaultChoose = data.data[0].id;
  curriculumValue.value = defaultChoose;
  curriculumErrorValue.value = defaultChoose;
  getMockExamListCur(defaultChoose);
  getErrorSubject(defaultChoose);
  getSelectMockExamRecordList(defaultChoose);
});

const getMockExamListCur = (id: number) => {
  selectMockExamList({ currId: id }).then((res) => {
    const data = res;
    curriculumMockExamList.value = data.data;
  });
};
// 模拟考试记录
const getSelectMockExamRecordList = (id: string) => {
  selectMockExamRecordList({ currId: id }).then((res) => {
    const data = res;

    curriculumMockExamRecord.value = data.data;
  });
};

const curriculumChange = (value: any) => {
  getMockExamListCur(value);
  getSelectMockExamRecordList(value);
};
// 我的错题

let curriculumErrorValue = ref("");
let errorSubjectPage = ref(1);
let errorSubjectLimit = ref(2);
let total = ref(0);

let errorSubjectList: any = ref([]);
const curriculumErrorChange = (value: any) => {
  getErrorSubject(value);
};
const handleCurrentChange = (val: any) => {
  errorSubjectPage.value = val;
  getErrorSubject(curriculumErrorValue.value);
};
const getErrorSubject = (id: string) => {
  selectMyErrorQuestionList({
    currId: id,
    page: errorSubjectPage.value,
    limit: errorSubjectLimit.value,
  }).then((res) => {
    const { data } = res;
    errorSubjectList.value = data.data;
    total.value = data.total;
  });
};

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "header-box" }, [
      _createElementVNode("span", null, "我的题库")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_tabs, { type: "card" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, { label: "模拟考试" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_el_select, {
                    modelValue: _unref(curriculumValue),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(curriculumValue) ? (curriculumValue).value = $event : curriculumValue = $event)),
                    placeholder: "选择课程",
                    onChange: curriculumChange
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(curriculumOptions), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.id,
                          label: item.name,
                          value: item.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(curriculumMockExamList), (v) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: v.id,
                      class: "content-item"
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("img", {
                          src: v.coverImg
                        }, null, 8, _hoisted_6)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(v.name), 1),
                          _createElementVNode("span", _hoisted_9, _toDisplayString(v.releaseTime), 1)
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_el_button, {
                            size: "small",
                            onClick: ($event: any) => (_unref(router).push(`/doingExamination/${v.id}/3`))
                          }, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode("开始测试")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ])
                    ]))
                  }), 128)),
                  _withDirectives(_createVNode(_component_el_empty, {
                    "image-size": 100,
                    image: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fb209937dc2276a7cb5a47fb831879af25c52fa1b25567-NGX9jR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642128140&t=009a26e4722b76fdd40bb57359b521ef",
                    class: "empty-status"
                  }, null, 512), [
                    [_vShow, _unref(curriculumMockExamList).length == 0]
                  ])
                ]),
                _createElementVNode("div", null, [
                  _cache[3] || (_cache[3] = _createElementVNode("span", null, "已完成", -1)),
                  _createElementVNode("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(curriculumMockExamRecord), (v) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: v.id,
                        class: "content-item",
                        onClick: ($event: any) => (_unref(router).push(`/doneExamination/${v.id}/3`))
                      }, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("img", {
                            src: v.coverImg
                          }, null, 8, _hoisted_13)
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_15, _toDisplayString(v.name), 1),
                            _createElementVNode("span", _hoisted_16, _toDisplayString(v.releaseTime), 1)
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("span", null, _toDisplayString(v.score) + "分", 1)
                          ])
                        ])
                      ], 8, _hoisted_11))
                    }), 128)),
                    _withDirectives(_createVNode(_component_el_empty, {
                      "image-size": 100,
                      image: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fb209937dc2276a7cb5a47fb831879af25c52fa1b25567-NGX9jR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642128140&t=009a26e4722b76fdd40bb57359b521ef",
                      class: "empty-status"
                    }, null, 512), [
                      [_vShow, _unref(curriculumMockExamRecord).length == 0]
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "我的错题" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_el_select, {
                    modelValue: _unref(curriculumErrorValue),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(curriculumErrorValue) ? (curriculumErrorValue).value = $event : curriculumErrorValue = $event)),
                    placeholder: "选择课程",
                    onChange: curriculumErrorChange
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(curriculumOptions), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.id,
                          label: item.name,
                          value: item.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(SubjectServerDetail, {
                    style: {"min-height":"500px"},
                    subjectList: _unref(errorSubjectList)
                  }, null, 8, ["subjectList"]),
                  _withDirectives(_createVNode(_component_el_empty, {
                    "image-size": 100,
                    image: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fb209937dc2276a7cb5a47fb831879af25c52fa1b25567-NGX9jR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642128140&t=009a26e4722b76fdd40bb57359b521ef"
                  }, null, 512), [
                    [_vShow, _unref(errorSubjectList).length == 0]
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_el_pagination, {
                    "page-size": _unref(errorSubjectPage),
                    background: "",
                    "prev-text": "上一页",
                    "next-text": "下一页",
                    "jumper-text": "跳转",
                    layout: "prev, pager, next, jumper",
                    total: _unref(total),
                    onCurrentChange: handleCurrentChange
                  }, null, 8, ["page-size", "total"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})