import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "my-certificate" }
const _hoisted_2 = { class: "content-box" }
const _hoisted_3 = { class: "content-already-box" }
const _hoisted_4 = { class: "content-item-box" }
const _hoisted_5 = ["src", "onClick"]
const _hoisted_6 = { class: "no-already-box" }
const _hoisted_7 = { class: "content-item-box" }
const _hoisted_8 = ["src", "onClick"]
const _hoisted_9 = { class: "content-item-bottom" }
const _hoisted_10 = ["onClick"]

import { ref, computed } from 'vue'
import { certificateSelectList, applyCertSendMailTeacher } from "@/api/person-list"
import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const router = useRouter()

let alreadyBuy: any = ref([])
let alreadyNoBuy: any = ref([])

const getData = () => {
    certificateSelectList().then(res => {
        const { data } = res;
        let temp: any = []
        let temp2: any = []
        data.forEach((v: any) => {
            if (v.isBuy == 1) {
                // .push(v)
                temp.push(v)
            } else {
                temp2.push(v)
                // alreadyNoBuy.value.push(v)
            }
        });

        alreadyBuy.value = temp
        alreadyNoBuy.value = temp2



    })
}

getData()



const goCertificateDetail = (id: number) => {
    router.push(`/certificateDetail/${id}`)
}

const submitCertificate = (item: any) => {
    const { id, name } = item
    applyCertSendMailTeacher({ certId: id, certName: name }).then(res => {
        getData()
    })
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "header-box" }, [
      _createElementVNode("span", null, "我的证书")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("div", null, [
          _createElementVNode("span", { class: "content-title" }, "已报名")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(alreadyBuy), (v) => {
            return (_openBlock(), _createElementBlock("div", {
              key: v.id,
              class: "content-item"
            }, [
              _createElementVNode("img", {
                src: v.img,
                onClick: ($event: any) => (goCertificateDetail(v.id))
              }, null, 8, _hoisted_5),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "content-item-bottom" }, [
                _createElementVNode("div", { class: "fix-already" }, "已报名")
              ], -1))
            ]))
          }), 128)),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fix-space-self" }, null, -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fix-space-self" }, null, -1))
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "black-line" }, null, -1)),
      _createElementVNode("div", _hoisted_6, [
        _cache[6] || (_cache[6] = _createElementVNode("div", null, [
          _createElementVNode("span", { class: "content-title" }, "可申请")
        ], -1)),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(alreadyNoBuy), (v) => {
            return (_openBlock(), _createElementBlock("div", {
              key: v.id,
              class: "content-item"
            }, [
              _createElementVNode("img", {
                src: v.img,
                onClick: ($event: any) => (goCertificateDetail(v.id))
              }, null, 8, _hoisted_8),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  class: "fix-no-already",
                  onClick: ($event: any) => (submitCertificate(v))
                }, _toDisplayString(v.isApply == 1 ? "已申请" : "申请证书"), 9, _hoisted_10)
              ])
            ]))
          }), 128)),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fix-space-self" }, null, -1)),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "fix-space-self" }, null, -1))
        ])
      ])
    ])
  ]))
}
}

})