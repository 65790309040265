import router from "./router";
import store from "./store";
import { getToken } from "@/utils/auth";

router.beforeEach(async (to, from, next) => {
  if (getToken()) {
    await store.dispatch("getInfo");
  }
  next();
});
