import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/person-center-icon.png'
import _imports_1 from '../../assets/images/my-certificate-icon.png'
import _imports_2 from '../../assets/images/my-curriculum-icon.png'
import _imports_3 from '../../assets/images/learn-record-icon.png'
import _imports_4 from '../../assets/images/my-subject-icon.png'
import _imports_5 from '../../assets/images/my-exam-icon.png'


const _hoisted_1 = { class: "person-detail-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "person-box" }
const _hoisted_4 = { class: "person-into-box" }
const _hoisted_5 = { class: "person-left" }
const _hoisted_6 = { class: "person-right" }
const _hoisted_7 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import { useRoute } from "vue-router"

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute()


return (_ctx: any,_cache: any) => {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_menu, {
            class: "el-menu-vertical-demo",
            router: "",
            "default-active": _unref(route).path
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, { index: "/personCenter" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("img", {
                    class: "index-icon-img",
                    src: _imports_0
                  }, null, -1),
                  _createElementVNode("div", { class: "index-icon-text" }, [
                    _createElementVNode("span", null, "个人资料")
                  ], -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "/personCenter/myCertificate" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("img", {
                    class: "index-icon-img",
                    src: _imports_1
                  }, null, -1),
                  _createElementVNode("div", { class: "index-icon-text" }, [
                    _createElementVNode("span", null, "我的证书")
                  ], -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "/personCenter/myCourse" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("img", {
                    class: "index-icon-img",
                    src: _imports_2
                  }, null, -1),
                  _createElementVNode("div", { class: "index-icon-text" }, [
                    _createElementVNode("span", null, "我的课程")
                  ], -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "/personCenter/learningRecord" }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("img", {
                    class: "index-icon-img",
                    src: _imports_3
                  }, null, -1),
                  _createElementVNode("div", { class: "index-icon-text" }, [
                    _createElementVNode("span", null, "学习记录")
                  ], -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "/personCenter/myQuestionBank" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("img", {
                    class: "index-icon-img",
                    src: _imports_4
                  }, null, -1),
                  _createElementVNode("div", { class: "index-icon-text" }, [
                    _createElementVNode("span", null, "我的题库")
                  ], -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "/personCenter/myExam" }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("img", {
                    class: "index-icon-img",
                    src: _imports_5
                  }, null, -1),
                  _createElementVNode("div", { class: "index-icon-text" }, [
                    _createElementVNode("span", null, "我的考试")
                  ], -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["default-active"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_router_view)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(Footer)
    ])
  ]))
}
}

})