import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "content-box" }
const _hoisted_2 = { class: "learn-title" }

import { ref } from 'vue'
import { selectMyLearningRecordList } from "@/api/person-list"
import HorizontalBar from "@/components/HorizontalBar/index.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

let learningRecordList: any = ref([])

selectMyLearningRecordList().then((res) => {
    const { data } = res
    learningRecordList.value = data
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "header-box" }, [
      _createElementVNode("span", null, "学习记录")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(learningRecordList), (v) => {
        return (_openBlock(), _createElementBlock("div", {
          key: v.id
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(v.certName), 1),
          _createElementVNode("div", null, [
            _createVNode(HorizontalBar, {
              horizontalBar: { list: v.courseVoList, isRequireAccount: false }
            }, null, 8, ["horizontalBar"])
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})