import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../../assets/images/up-approval.png'
import _imports_1 from '../../assets/images/under-approval.png'


const _hoisted_1 = { class: "adapt-box module-board-box" }
const _hoisted_2 = { class: "module-board-into-box" }
const _hoisted_3 = {
  key: 0,
  class: "module-board-header"
}
const _hoisted_4 = { class: "module-board-data" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "board-data-display" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "board-data-bottom-border" }
const _hoisted_9 = { class: "board-data-title" }
const _hoisted_10 = { class: "board-data-introduce" }
const _hoisted_11 = { class: "right-post" }
const _hoisted_12 = {
  key: 0,
  src: _imports_0
}
const _hoisted_13 = {
  key: 1,
  src: _imports_1
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "board-data-display" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "board-data-bottom-border" }
const _hoisted_18 = { class: "board-data-title" }
const _hoisted_19 = { class: "board-data-introduce" }
const _hoisted_20 = {
  key: 0,
  class: "module-board-footer"
}

import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    certificateInfo: {}
  },
  setup(__props: any) {

const router = useRouter()
const props = __props





return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.certificateInfo.isRequireHeader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
            _createElementVNode("span", null, "专业证书", -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certificateInfo.alreadySubmitList, (v) => {
          return (_openBlock(), _createElementBlock("div", {
            key: v.id,
            class: "board-data-item",
            onClick: ($event: any) => (_unref(router).push(`/doneExamination/${v.recordId}/1`))
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("img", {
                class: "data-display-img",
                src: v.coverImg
              }, null, 8, _hoisted_7)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", null, _toDisplayString(v.name), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", null, _toDisplayString(v.detail), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              (v.isReview == 1)
                ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                : (_openBlock(), _createElementBlock("img", _hoisted_13)),
              _createElementVNode("span", null, _toDisplayString(v.isReview == 1 ? "已批阅" : "批阅中"), 1)
            ])
          ], 8, _hoisted_5))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certificateInfo.noSubmitList, (v) => {
          return (_openBlock(), _createElementBlock("div", {
            key: v.id,
            class: "board-data-item",
            onClick: ($event: any) => (_unref(router).push(`/examinationDetail/${v.id}`))
          }, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("img", {
                class: "data-display-img",
                src: v.coverImg
              }, null, 8, _hoisted_16)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("span", null, _toDisplayString(v.name), 1)
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("span", null, _toDisplayString(v.detail), 1)
              ])
            ])
          ], 8, _hoisted_14))
        }), 128)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fix-space" }, null, -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fix-space" }, null, -1)),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fix-space" }, null, -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "fix-space" }, null, -1))
      ])
    ]),
    (_ctx.certificateInfo.isRequireHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push(`/curriculum/2`)))
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("更多证书")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})