import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "done-detail-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "done-box" }
const _hoisted_4 = { class: "done-into-box" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "tip-pass" }
const _hoisted_7 = {
  key: 0,
  class: "tip-status"
}
const _hoisted_8 = { class: "tip-time" }
const _hoisted_9 = { class: "tip-button" }
const _hoisted_10 = { class: "question" }
const _hoisted_11 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import SubjectServerDetail from "@/components/SubjectServerDetail/index.vue";
import {
  selectIsCanRetest,
  selectMockExamDetail,
  selectExamDetail,
} from "@/api/examination-list";
import {} from "@/api/examination-list";
import { ref, Ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute();
const router = useRouter();

let doneExaminationDetail: any = ref({});

if (String(route.params.type) == "1" || String(route.params.type) == "2") {
  selectExamDetail({ recordId: String(route.params.id) }).then((res) => {
    const { data } = res;
    data.isReviewName = data.isReview == 1 ? data.score + '分' : "批阅中";

    data.examRecordAnswerVoList.forEach((v: any) => {
      switch (v.type) {
        case "1":
          break;
        case "2":
          if (v.userAnswerList != null) {
            v.userAnswerList = v.userAnswer.split(";");
          }
          break;
        case "3":
          break;
        case "4":
          if (v.blankList != null) {
            v.blankList = v.userAnswer.split(";");
          }
          break;

        default:
          break;
      }
    });
    doneExaminationDetail.value = data;
  });
} else {
  selectMockExamDetail({ recordId: String(route.params.id) }).then((res) => {
    const { data } = res;
    data.isReviewName = data.isReview == 1 ? "已批阅" : "批阅中";

    data.examRecordAnswerVoList.forEach((v: any) => {
      switch (v.type) {
        case "1":
          break;
        case "2":
          if (v.userAnswerList != null) {
            v.userAnswerList = v.userAnswer.split(";");
          }
          break;
        case "3":
          break;
        case "4":
          if (v.blankList != null) {
            v.blankList = v.userAnswer.split(";");
          }
          break;
        default:
          break;
      }
    });
    doneExaminationDetail.value = data;
  });
}

const againExam = () => {
  selectIsCanRetest({ recordId: String(route.params.id) }).then((res) => {
    const data: any = res;
    if (data.code == 200) {
      router.push(`/doingExamination/${doneExaminationDetail.value.examId}/1`);
    }
  });
};

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(doneExaminationDetail).userName) + ", 恭喜你完成此次考试", 1),
          (_unref(route).params.type != '3')
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(doneExaminationDetail).isReviewName), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_8, "本次考试提交时间" + _toDisplayString(_unref(doneExaminationDetail).updateTime), 1),
          _createElementVNode("div", _hoisted_9, [
            (
                _unref(doneExaminationDetail).isPass == 2 &&
                _unref(doneExaminationDetail).isReview == 1
              )
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  onClick: againExam
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("重考")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(SubjectServerDetail, {
            subjectList: _unref(doneExaminationDetail).examRecordAnswerVoList
          }, null, 8, ["subjectList"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(Footer)
    ])
  ]))
}
}

})