import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "adapt-box count-swiper-box" }
const _hoisted_2 = { class: "count-swiper-into-box" }
const _hoisted_3 = { class: "count-swiper-data" }
const _hoisted_4 = { class: "count-swiper-item" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "count-bottom-text" }
const _hoisted_7 = { class: "count-board-footer" }

import { lecturerInfoInterface } from "@/types/index"
import { Autoplay, Lazy } from 'swiper';
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    lecturerInfo: {}
  },
  setup(__props: any) {

const router = useRouter()
const props = __props

let modules = [Autoplay, Lazy]

const goExpertDetail = (id: number, type: number) => {
    router.push(`/expertDetail/${id}/${type}`)
}

return (_ctx: any,_cache: any) => {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "count-swiper-header" }, [
        _createElementVNode("div", { class: "title-box" }, [
          _createElementVNode("span", null, "专家与讲师"),
          _createElementVNode("p"),
          _createElementVNode("p"),
          _createElementVNode("p"),
          _createElementVNode("p")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_swiper, {
          "slides-per-view": 5,
          "space-between": 10,
          modules: _unref(modules),
          autoplay: "",
          lazy: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.lecturerInfo.list, (v, i) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: v.id,
                onClick: ($event: any) => (goExpertDetail(v.id, v.t))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: v.img,
                      class: ""
                    }, null, 8, _hoisted_5),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", null, _toDisplayString(v.name), 1),
                      _createElementVNode("span", null, _toDisplayString(v.title), 1)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modules"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push(`/moreTeacher`)))
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("更多专家讲师")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})