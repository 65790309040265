import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/clock-icon.png'
import _imports_1 from '../../assets/images/paper-icon.png'
import _imports_2 from '../../assets/images/all-count-icon.png'


const _hoisted_1 = { class: "adapt-box curriculum-box" }
const _hoisted_2 = { class: "curriculum-into-box" }
const _hoisted_3 = { class: "header-container" }
const _hoisted_4 = { class: "certificate-display" }
const _hoisted_5 = { class: "certificate-into-box" }
const _hoisted_6 = { class: "certificate-into-count" }
const _hoisted_7 = { class: "subject-container" }
const _hoisted_8 = { class: "submit-content-box" }
const _hoisted_9 = { class: "footer-container" }

import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import SubjectServer from "@/components/SubjectServer/index.vue";
import {
  selectTestPaperByExamId,
  selectSubjectListByTestId,
  insertFirstExam,
  insertRetestExam,
  insertMockExam,
} from "@/api/examination-list";
import { useRoute } from "vue-router";
import { reactive, ref } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import router from "../../router/index";

import { onMounted } from 'vue';

import axios from 'axios';
import { getToken } from "@/utils/auth";
import { getInfo } from "@/api/header-list";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute();

let useTime: any = ref(0);
let remainingTime: any = ref("");
let timer = setInterval(() => {
  useTime.value = useTime.value + 1;
  remainingTime.value = formatSeconds(
    testDetailInfo.data.duration * 60 - useTime.value
  );
  if (testDetailInfo.data.duration != null) {
    if (remainingTime.value == 0) {
      clearInterval(timer)
      submitTestPaper(true);
    }
  }
}, 1000);

const formatSeconds = (value: any) => {
  var theTime: any = parseInt(value);
  var theTime1: any = 0;
  var theTime2: any = 0;
  var theTime3: any = 0;
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
      if (theTime2 > 24) {
        theTime3 = parseInt(theTime2 / 24);
        theTime2 = parseInt(theTime2 % 24);
      }
    }
  }
  var result = "";
  if (theTime > 0) {
    result = "" + parseInt(theTime);
  }
  if (theTime1 > 0) {
    result = "" + parseInt(theTime1) + ":" + result;
  }
  if (theTime2 > 0) {
    result = "" + parseInt(theTime2) + ":" + result;
  }
  if (theTime3 > 0) {
    result = "" + parseInt(theTime3) + "天" + result;
  }
  return result;
};

let testDetailInfo: any = reactive({
  data: {},
});

let testPaperInfo: any = reactive({
  data: [],
});
selectTestPaperByExamId({ examId: String(route.params.id) }).then((res) => {
  const data = res.data;
  testDetailInfo.data = data;
  selectSubjectListByTestId({ testPaperId: data.id }).then((res) => {
    const data = res.data;
    data.forEach((v: any) => {
      switch (v.type) {
        case "1":
          v.answer = "";
          break;
        case "2":
          v.answer = [];
          break;
        case "4":
          v.blankList = [];
          for (let i = 0; i < v.vacancyNumber; i++) {
            v.blankList.push({ value: "" });
          }
          break;
        default:
          v.answer = "";
          break;
      }
    });
    testPaperInfo.data = data;
  });
});

const submitTestPaper = (isForce = false) => {
  let temp: any = [];
  let isPass: boolean = true;

  if (!isForce) {
    testPaperInfo.data.forEach((v: any) => {
      let tempThing: any = {};
      switch (v.type) {
        case "1":
          tempThing.subjectId = v.id;
          tempThing.answer = v.answer;
          temp.push(tempThing);
          if (v.answer == "") {
            isPass = false;
          }
          break;
        case "2":
          tempThing.subjectId = v.id;
          tempThing.answer = v.answer.join(",");
          temp.push(tempThing);
          v.answer.forEach((v: any) => {
            if (v.value == "") {
              isPass = false;
            }
          });
          break;
        case "4":
          tempThing.subjectId = v.id;
          tempThing.answer = v.blankList
            .map((maps: any) => maps.value)
            .join(";");
          temp.push(tempThing);
          v.blankList.forEach((v: any) => {
            if (v.value == "") {
              isPass = false;
            }
          });
          break;
        default:
          tempThing.subjectId = v.id;
          tempThing.answer = v.answer;
          temp.push(tempThing);
          if (v.answer == "") {
            isPass = false;
          }
          break;
      }
    });
  }

  const firstSubmit = () => {
    let submitThing: any = {};
    submitThing.examId = String(route.params.id);
    submitThing.testPaperId = testDetailInfo.data.id;
    submitThing.currId = testDetailInfo.data.currId;
    submitThing.useTime = useTime.value;
    submitThing.examUserAnswerDtoList = temp;

    insertFirstExam(submitThing).then((res) => {
      const data: any = res;
      if (data.code == 200) {
        router.replace(`/doneExamination/${data.data.recordId}/1`);
      }
    });
  };
  const againSubmit = () => {
    let submitThing: any = {};
    submitThing.examId = String(route.params.id);
    submitThing.testPaperId = testDetailInfo.data.id;
    submitThing.currId = testDetailInfo.data.currId;
    submitThing.useTime = useTime.value;
    submitThing.examUserAnswerDtoList = temp;
    submitThing.recordId = testDetailInfo.data.recordId;

    insertRetestExam(submitThing).then((res) => {
      const data: any = res;
      if (data.code == 200) {
        router.replace(`/doneExamination/${data.data.recordId}/2`);
      }
    });
  };
  const mockSubmit = () => {
    let submitThing: any = {};
    submitThing.examId = String(route.params.id);
    submitThing.testPaperId = testDetailInfo.data.id;
    submitThing.currId = testDetailInfo.data.currId;
    submitThing.useTime = useTime.value;
    submitThing.examUserAnswerDtoList = temp;

    insertMockExam(submitThing).then((res) => {
      const data: any = res;
      if (data.code == 200) {
        router.replace(`/doneExamination/${data.data.recordId}/3`);
      }
    });
  };
  if (isPass) {
    clearInterval(timer);
    if (String(route.params.type) == "1") {
      againSubmit();
    } else if (String(route.params.type) == "2") {
      firstSubmit();
    } else {
      mockSubmit();
    }
  } else {
    ElMessageBox.confirm("当前还有题目未做,是否提交", "提示", {
      confirmButtonText: "提交",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        if (String(route.params.type) == "1") {
          againSubmit();
        } else if (String(route.params.type) == "2") {
          firstSubmit();
        } else {
          mockSubmit();
        }
      })
      .catch(() => {});
  }
  // 关闭摄像头抓拍
  stopTimer();
  stopCamera();
  // 停止监听切屏
  window.removeEventListener('blur', blurDom)
  window.removeEventListener('focus', focusDom)
  // 退出全屏
  if (document.exitFullScreen) {
    document.exitFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  }
};

let focusCount  = ref(0);

const blurDom = () => {
  // 失去焦点执行操作
};

const focusDom = () => {
  focusCount.value++;
  if (focusCount.value > 3) {
    ElMessageBox.confirm("您已切出考试界面超过3次，现在自动交卷", "提示", {
      confirmButtonText: "确定",
      showCancelButton:false,
      showClose: false,
      closeOnClickModal: false,
      type: "warning",
    })
        .then(() => {
          if (String(route.params.type) == "1") {
            // 提交试卷
            let submitThing: any = {};
            submitThing.examId = String(route.params.id);
            submitThing.testPaperId = testDetailInfo.data.id;
            submitThing.currId = testDetailInfo.data.currId;
            submitThing.useTime = useTime.value;
            submitThing.examUserAnswerDtoList = [];
            submitThing.recordId = testDetailInfo.data.recordId;

            insertRetestExam(submitThing).then((res) => {
              const data: any = res;
              if (data.code == 200) {
                router.replace(`/doneExamination/${data.data.recordId}/2`);
              }
            });
          } else {
            // mockSubmit();
          }
        })
        .catch(() => {});
    // 关闭摄像头抓拍
    stopTimer();
    stopCamera();
    // 停止监听切屏
    window.removeEventListener('blur', blurDom)
    window.removeEventListener('focus', focusDom)

    // 退出全屏
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  }else{
    ElMessageBox.confirm("考试中请不要切出考试页面,超过3次将自动交卷。", "提示", {
      confirmButtonText: "确定",
      showCancelButton:false,
      type: "warning",
    })
  }

};

// 使用onMounted钩子函数在页面加载完成后执行操作
onMounted(() => {
  // 监听切屏事件
  window.addEventListener('blur', blurDom);
  window.addEventListener('focus',  focusDom);

  // 全屏
  // 确保浏览器支持全屏API
  if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen();
  } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
    document.documentElement.mozRequestFullScreen();
  } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
    document.documentElement.webkitRequestFullscreen();
  } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
    document.documentElement.msRequestFullscreen();
  }

  // 功能
  // 1.禁用右键菜单
  document.oncontextmenu = new Function("event.returnValue=false");
  // 2.禁止键盘F12键
  document.addEventListener("keydown", function (e) {
    if (e.key === "F12") {
      e.preventDefault();
    }
    // 禁止 Ctrl + Shift + I 打开开发者工具
    if (e.ctrlKey && e.shiftKey && e.key === 'I') {
      e.preventDefault();
    }
    // 禁止 Ctrl + Shift + C 打开检查元素
    if (e.ctrlKey && e.shiftKey && e.key === 'C') {
      e.preventDefault();
    }
    // 禁止 Ctrl + table
    if (e.ctrlKey && e.key == 'Tab') {
      e.preventDefault();
    }
    // 禁止 alt + table
    if (e.altKey && e.key == 'Tab') {
      e.preventDefault();
    }
    // 禁止 F11
    if (e.key === 'F11') {
      e.preventDefault();
    }
    // 禁止 Esc
    if (e.key === 'Escape') {
      e.preventDefault();
    }
  });

  startCamera();
  startTimer();
  tips();
});

// ------------自动拍照功能-----------------
const video = ref(null);
let intervalId;

// 考前提示
function tips(){
  ElMessageBox.confirm("考试开始，请将摄像头位置正确摆放，考试将记录考生行为，请规范答题！", "提示", {
    confirmButtonText: "确定",
    showCancelButton:false,
    type: "warning",
  })
}

function startCamera() {
  const constraints = {
    video: { width: 800, height: 600 }
  };
  navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        video.value.srcObject = stream;
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });
}

function stopCamera() {
  if (video.value.srcObject) {
    video.value.srcObject.getTracks().forEach(track => {
      track.stop();
    });
    video.value.srcObject = null;
  }
}

function takePhoto() {
  const canvas = document.createElement('canvas');
  canvas.width = video.value.videoWidth;
  canvas.height = video.value.videoHeight;
  canvas.getContext('2d').drawImage(video.value, 0, 0, canvas.width, canvas.height);
  const imageUrl = canvas.toDataURL('image/png');
  // 获取个人信息 作为文件名,将图片转为二进制文件
  getInfo()
      .then((res: any) => {
        const { data } = res;
        if (res.code == 200) {
          const fileName = data.mobile +"-"+data.idCard +"-"+testDetailInfo.data.currId+".png";
          // 将数据 URL 转换为二进制数据
          fetch(imageUrl)
              .then(response => response.blob())
              .then(blob => {
                // 创建一个文件对象
                const file = new File([blob], fileName, {
                  type: "image/png",
                });
                uploadPhoto(file);
              });
        } else {
          console.error('Error getUserInfo message:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error getUserInfo:', error);
      });
}

function uploadPhoto(imageUrl) {
  const formData = new FormData();
  formData.append('file', imageUrl);
  // TODO 图片上传地址
  axios.post('https://gateway.zryyedu.com/api/user/upload/oss/uploadFile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': "Bearer " + getToken()
    }
  })
      .then(response => {
        // console.log('Photo uploaded:', response);
      })
      .catch(error => {
        console.error('Error uploading photo:', error);
      });
}

function startTimer() {
  intervalId = setInterval(() => {
    takePhoto();
  }, 60000); // TODO 每一分钟拍照并上传一次（填写毫秒）
}

function stopTimer() {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null
  }
}
// ------------自动拍照功能-----------------


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(Header)
      ])
    ]),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("video", {
        ref_key: "video",
        ref: video,
        autoplay: ""
      }, null, 512), [
        [_vShow, false]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", null, [
            _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(testDetailInfo).data.duration ? _unref(remainingTime) : "无限制"), 1),
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "考试时长", -1))
          ]),
          _createElementVNode("div", null, [
            _cache[3] || (_cache[3] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(testDetailInfo).data.totalNumber), 1),
            _cache[4] || (_cache[4] = _createElementVNode("span", null, "题目数量(题)", -1))
          ]),
          _createElementVNode("div", null, [
            _cache[5] || (_cache[5] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_unref(testDetailInfo).data.totalScore), 1),
            _cache[6] || (_cache[6] = _createElementVNode("span", null, "考试总分(分)", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(SubjectServer, {
            subjectList: _unref(testPaperInfo).data
          }, null, 8, ["subjectList"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (submitTestPaper(false)))
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("提交试卷")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(Footer)
    ])
  ]))
}
}

})