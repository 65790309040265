import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, unref as _unref } from "vue"
import _imports_0 from '../../assets/images/logo.png'


const _hoisted_1 = { class: "adapt-box header-box" }
const _hoisted_2 = { class: "header-into-box" }
const _hoisted_3 = { class: "nav-bar" }
const _hoisted_4 = { class: "login-box" }
const _hoisted_5 = { class: "person-info-box" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "login-submit-scale" }

import { ref, reactive, computed, nextTick } from "vue";
import { pcSendSmsCode, pcSendSmsCodeValidate } from "@/api/header-list";
import { getToken, removeToken } from "@/utils/auth";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const store = useStore();
const router = useRouter();
const getterInstance = computed(() => store.getters.user);

const openLogin = (status: any) => {
	store.dispatch("updateLogin", status);
};

// 退出登录

const loginOut = () => {
	removeToken();
	store.dispatch("removeInfo");
	router.push("/");
};

// 登录
const phoneUser = reactive({
	phone: "",
	captchaCode: "",
	captchaCodeUrl: "",
	code: "",
	uuid: "",
});

const getCaptchaCode = () => {
	pcSendSmsCode().then((res) => {
		const { data } = res;
		phoneUser.captchaCodeUrl = data.img;
		phoneUser.uuid = data.uuid;
	});
};

getCaptchaCode();

const getCode = () => {
	console.log("getCode");
	// let successLength = 0;
	// phoneUserRef.value.validateField(["phone", "captchaCode"], async (errorMessage: string) => {
	// 	console.log(errorMessage);
	// 	if (!errorMessage) {
	// 		successLength = successLength + 1;
			// if (countdown == 60 && successLength == 2) {
                if (countdown == 60) {
				pcSendSmsCodeValidate({
					phone: phoneUser.phone,
					captchaCode: phoneUser.captchaCode,
					uuid: phoneUser.uuid,
				}).then((res) => {
					let data: any = res;
					if (data.code == 200) {
						setTime();
					}
				});
			}
	// 	} else {
	// 		return false;
	// 	}
	// });
};
// 验证码倒计时
let getPhoneTip = ref("获取验证码");
let countdown = 60;
const setTime = () => {
	let siv = setInterval(() => {
		if (countdown == 0) {
			countdown = 60;
			getPhoneTip.value = "获取验证码";
			clearInterval(siv);
		} else {
			getPhoneTip.value = String(countdown);
			countdown--;
		}
	}, 1000);
};

const phoneUserRule = {
	phone: [
		{
			required: true,
			message: "手机号不能为空",
			trigger: "blur",
		},
	],
	captchaCode: [
		{
			required: true,
			message: "图形验证码不能为空",
			trigger: "blur",
		},
	],
	code: [
		{
			required: true,
			message: "短信验证码不能为空",
			trigger: "blur",
		},
	],
};

const phoneUserRef: any = ref(null);

const goLogin = () => {
	phoneUserRef.value.validate(async (valid: boolean) => {
		if (valid) {
			await store
				.dispatch("login", {
					mobile: phoneUser.phone,
					code: phoneUser.code,
				})
				.then(async (res) => {
					const { data } = res;
					store.dispatch("getInfo");
					openLogin(false);
					store.dispatch("isRouterView", false);
					await nextTick();
					store.dispatch("isRouterView", true);
				});
		} else {
			console.log("error submit!!");
			return false;
		}
	});
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("div", null, [
        _createElementVNode("img", { src: _imports_0 })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "nav-item",
          to: "/"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("首页")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "nav-item",
          to: "/curriculum/1"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("课程")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "nav-item",
          to: "/examination"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("考试")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "nav-item",
          to: "/consulting"
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("资讯")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!getterInstance.value.user.realName)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (openLogin(true)))
            }, "登录"))
          : (_openBlock(), _createBlock(_component_el_dropdown, {
              key: 1,
              trigger: "click",
              placement: "top-start"
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          class: "nav-item",
                          to: "/personCenter"
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode("个人中心")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", { onClick: loginOut }, " 退出登录 ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (getterInstance.value.user.headPortrait)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: getterInstance.value.user.headPortrait
                      }, null, 8, _hoisted_6))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, _toDisplayString(getterInstance.value.user.realName), 1)
                ])
              ]),
              _: 1
            }))
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: getterInstance.value.isLogin,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((getterInstance.value.isLogin) = $event)),
      width: "25%",
      showClose: false
    }, {
      default: _withCtx(() => [
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "logo-login-box" }, [
          _createElementVNode("img", { src: _imports_0 })
        ], -1)),
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createVNode(_component_el_form, {
              ref_key: "phoneUserRef",
              ref: phoneUserRef,
              model: phoneUser,
              rules: phoneUserRule
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { prop: "phone" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: phoneUser.phone,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((phoneUser.phone) = $event)),
                      placeholder: "手机号",
                      style: {"margin-top":"20px"}
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, {
                  class: "post-container",
                  prop: "captchaCode"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: phoneUser.captchaCode,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((phoneUser.captchaCode) = $event)),
                      placeholder: "验证码"
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("span", {
                      class: "captcha-code",
                      onClick: getCaptchaCode
                    }, [
                      _createElementVNode("img", {
                        src: 'data:image/png;base64,' + phoneUser.captchaCodeUrl
                      }, null, 8, _hoisted_7)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, {
                  class: "post-container",
                  prop: "code"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: phoneUser.code,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((phoneUser.code) = $event)),
                      placeholder: "手机验证码"
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("span", {
                      class: "captcha-code",
                      onClick: getCode
                    }, [
                      _createVNode(_component_el_button, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(getPhoneTip)), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_button, { onClick: goLogin }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("登录")
              ])),
              _: 1
            })
          ])
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "tip-login-box" }, [
          _createElementVNode("span", null, "如有疑问请联系我们-"),
          _createElementVNode("span", null, "400123221")
        ], -1))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})